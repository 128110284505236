import cx from "classnames"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import styles from "./blurbs.module.css"

export default function Blurbs({ block }) {
  const { attrs, innerBlocks } = block
  if (innerBlocks.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      width="full"
      gap={attrs.blockGap ?? "normal"}
      className={cx(styles.wrapper, {
        whiteBackground: attrs.theme === "white"
      })}>
      <div className={cx(styles["layout-" + attrs.layout])}>
        <Blocks blocks={innerBlocks} />
      </div>
    </Block>
  )
}
